<template>
  <div class="duvidas">
    <Voltar />
    <h1 class="titulo">Dúvidas (FAQ)</h1>
    <!-- <div class="pesquisar">
      <input type="search" autocomplete="on" placeholder="Buscar..." />
      <button>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="20px"
          height="20px"
          viewBox="0 0 310.42 310.42"
          style="enable-background: new 0 0 310.42 310.42"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M273.587,214.965c49.11-49.111,49.109-129.021,0-178.132c-49.111-49.111-129.02-49.111-178.13,0
                                        C53.793,78.497,47.483,140.462,76.51,188.85c0,0,2.085,3.498-0.731,6.312c-16.065,16.064-64.263,64.263-64.263,64.263
                                        c-12.791,12.79-15.836,30.675-4.493,42.02l1.953,1.951c11.343,11.345,29.229,8.301,42.019-4.49c0,0,48.096-48.097,64.128-64.128
                                        c2.951-2.951,6.448-0.866,6.448-0.866C169.958,262.938,231.923,256.629,273.587,214.965z M118.711,191.71
                                        c-36.288-36.288-36.287-95.332,0.001-131.62c36.288-36.287,95.332-36.288,131.619,0c36.288,36.287,36.288,95.332,0,131.62
                                        C214.043,227.996,155,227.996,118.711,191.71z"
              />
              <g>
                <path
                  d="M126.75,118.424c-1.689,0-3.406-0.332-5.061-1.031c-6.611-2.798-9.704-10.426-6.906-17.038
                                            c17.586-41.559,65.703-61.062,107.261-43.476c6.611,2.798,9.704,10.426,6.906,17.038c-2.799,6.612-10.425,9.703-17.039,6.906
                                            c-28.354-11.998-61.186,1.309-73.183,29.663C136.629,115.445,131.815,118.424,126.75,118.424z"
                />
              </g>
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </button>
    </div> -->

    <details v-for="duvida in state.duvidas" :key="duvida.id">
      <summary>{{ duvida.pergunta }}</summary>
      <p>
        {{ duvida.resposta }}
      </p>
    </details>

    <footer class="rodape--voltar">
      <a href="#">
        <svg
          version="1.1"
          id="Camada_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 29 27"
          style="enable-background: new 0 0 29 27"
          xml:space="preserve"
        >
          <g>
            <path
              d="M19,27h-9C4.5,27,0,22.5,0,17v-7C0,4.5,4.5,0,10,0h9c5.5,0,10,4.5,10,10v7C29,22.5,24.5,27,19,27z M10,2c-4.4,0-8,3.6-8,8
                                    v7c0,4.4,3.6,8,8,8h9c4.4,0,8-3.6,8-8v-7c0-4.4-3.6-8-8-8H10z"
            />
          </g>
          <path
            d="M13.9,12.8c-0.6,0.4-0.6,1.3,0,1.7l6.1,3.8v1.9c0,0.8-0.9,1.3-1.5,0.8L7.9,14.4c-0.6-0.4-0.6-1.3,0-1.7l10.6-6.6
                                C19.1,5.7,20,6.2,20,7v1.9L13.9,12.8z"
          />
        </svg>
        Voltar ao início
      </a>
    </footer>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      duvidas: {}
    })

    async function getDuvidas () {
      try {
        state.isLoading = true
        const { data, errors } = await services.duvidas.duvidas()

        if (!errors) {
          state.duvidas = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Dúvidas não encontradas')
        }

        if (errors.status === 401) {
          toast.error('Dúvidas Inválido')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar as Dúvidas!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getDuvidas()

    return {
      router,
      state
    }
  },
  components: { Voltar }
}
</script>
